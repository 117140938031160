<template>
    <v-container class="px-10">
      <v-row justify="center">
        
        <v-col class="mt-8">
         
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            class="mb-3"
            single-line
            hide-details
          ></v-text-field>
          <v-data-table :dark="$store.state.isDarkMode"
            :search="search"
            :headers="headers"
            id="table"
            :items="data"
            :loading="$store.state.restrictions.loading"
            style="border: 1px solid #999"
            :options.sync="$store.state.restrictions.options"
            :server-items-length="$store.state.restrictions.serverTotal"
            disable-sort
          >
            <template v-slot:item.actions="{ item }">
              <div class="d-flex justify-center">
                <v-icon
                  color="red"
                  style="cursor: pointer"
                  class="px-2"
                  @click="handleDelete(item)"
                >mdi-delete
                </v-icon>
                <v-icon
                  color="blue"
                  class="mx-2"
                  style="cursor: pointer"
                  @click="handleDisplay(item)"
                >mdi-eye
                </v-icon>
              </div>
  
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      
    </v-container>
  </template>
  <script>
  import axios from "axios";
  import {mapActions, mapGetters} from "vuex";
  
  
  export default {
    
    data() {
      return {
        search: '',
        display: false,
        item: null,
        
      };
    },
    mounted() {
      this.fetch()
    },
    watch: {
      "$store.state.restrictions.options": function () {
        this.fetch()
      },
    },
    computed: {
      ...mapGetters({
        data: 'restrictions/data',
        headers: 'restrictions/headers'
      })
    },
    methods: {
      ...mapActions({
        fetch: 'restrictions/fetch',
        delete: 'restrictions/delete',
        setPayment: 'restrictions/setPayment',
      }),
      handleDisplay(item) {
        this.display = true
        this.setPayment(item)
      },
      async handleDelete(item) {
        await this.delete(item.id)
      },
      handleEdit(item) {
        this.item = item
        this.display = true
      },
    },
  };
  </script>
  <style scoped>
  #table >>> th, #table >>> td {
    text-align: center !important;
  }
  </style>
  