<template>
    <div class="py-5">
        <v-row>
            <create-route typeTrip="GO"></create-route>
            <add-student></add-student>
            <v-btn md="2" style="margin: 15px; margin-top: 40px;"
            v-if="!selected.check"
            color="green"
            class="white--text mr-2 my-7"
            @click="$router.push('/showRestrictions')"
          >            
            {{ $t("showRestrictions") }}
          </v-btn>
            <generate-restrictions v-if="selected.check"
            :tableOptions="tableOptions" @re-fetch-data="fetchData" @re-fetch-paginated-data="fetchData($event)"></generate-restrictions>            
        </v-row>
        <v-row>
            <v-col>
                <v-card elevation="0" class="pt-5">
                    <routes-table :selected="selected" :tableOptions="tableOptions" @re-fetch-data="fetchData"
                        @re-fetch-paginated-data="fetchData($event)">
                    </routes-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import createRoute from './createRoute.vue'
import showRestrictions from './showRestrictions.vue'
import generateRestrictions from './generateRestrictions.vue'
import addStudent from './addStudentToRoute.vue'
import { driverRoute } from '../../../store/driverRoutes'
import routesTable from '../../../components/base/routesTable.vue'
export default {
    components: {
        createRoute,
        routesTable,
        addStudent,
        showRestrictions,
        generateRestrictions,
    },
    data() {
        return {
            driverRoute,
            selected:{
                id:null,
                check:false,
            }            
        }
    },
    methods: {
        fetchData(page) {
            let params = {
                page: page,
                type: "GO",
            }
            driverRoute.getRoutes(params)
        },
    },
    computed: {
        tableOptions() {
            return driverRoute.driversTable
        }
    },
    mounted() {
        let params = {
                page: 1,
                type: "GO",
            }
        driverRoute.getRoutes(params)
    }
}
</script>